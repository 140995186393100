<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/device')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="rounded-2xl bg-white p-8">

            <div class="">
                <div class="">
                    <span class="font-semibold text-base">Ubah Perangkat</span>
                </div>
                <div class="mt-4 grid grid-cols-12 gap-4">
                    <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                    <div class="col-span-12">
                        <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*"
                            @change="eventChange">

                        <ValidationObserver v-slot="{ invalid }" ref="form">
                            <form @submit.prevent="postData()" class="w-full">

                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <ValidationProvider v-slot="{ errors }" name="Kode" vid="code"
                                            class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Kode*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.code" type="text" disabled
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2 col-span-10 text-xs border-blue-300 bg-blue-50 placeholder-blue-200 text-blue-900 focus:border-blue-300`"
                                                    name="code" ref="code" id="code" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Nama" vid="name"
                                            class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Nama*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.name" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="name" ref="name" id="name" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Latitude"
                                            vid="latitude" class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Latitude*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.latitude" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="latitude" ref="latitude" id="latitude" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Longitude"
                                            vid="longitude" class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Longitude*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.longitude" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="longitude" ref="longitude" id="longitude" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider name="Password Lama" vid="prov_id"
                                            class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Kartu GSM</label>
                                            <div class="col-span-10">
                                                <select ref="gsm_id" name="gsm_id"
                                                    class="block border border-gray-200 w-full p-2 px-4 rounded mt-2 text-xs"
                                                    v-model="addData.gsm_id">
                                                    <option :value="item.id"
                                                        class="block border border-gray-200 w-full p-2 px-4 rounded mt-2 text-xs"
                                                        v-for="item in dataGsm" :key="item.id">{{ item.name }}</option>
                                                </select>

                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider v-slot="{ errors }" name="Titik Nol" vid="titik_nol"
                                            class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Titik Nol*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.titik_nol" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="titik_nol" ref="titik_nol" id="titik_nol" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div>
                                        <ValidationProvider v-slot="{ errors }" name="Siaga Satu" vid="nohp"
                                            class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Nomor Handphone</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.nohp" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="nohp" ref="nohp" id="nohp" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider v-slot="{ errors }" name="Siaga Satu" vid="nohp"
                                            class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Project</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.project" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="nohp" ref="nohp" id="nohp" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Siaga Satu"
                                            vid="siaga_1" class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Siaga 1*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.siaga_1" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="siaga_1" ref="siaga_1" id="siaga_1" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>


                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Siaga Dua"
                                            vid="siaga_2" class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Siaga 2*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.siaga_2" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="siaga_2" ref="siaga_2" id="siaga_2" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Siaga Tiga"
                                            vid="siaga_3" class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Siaga 3*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.siaga_3" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="siaga_3" ref="siaga_3" id="siaga_3" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider rules="required" v-slot="{ errors }" name="Siaga Empat"
                                            vid="siaga_4" class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Siaga 4*</label>
                                            <div class="col-span-10">
                                                <input v-model="addData.siaga_4" type="text"
                                                    :class="`block border border-gray-200 w-full p-2 px-4 rounded mt-2  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                                    name="siaga_4" ref="siaga_4" id="siaga_4" />
                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>

                                        <ValidationProvider v-slot="{ errors }" name="Status" vid="status"
                                            class="grid grid-cols-12 gap-4 items-center mb-2">
                                            <label for="" class="block col-span-2">Status</label>
                                            <div class="col-span-10">
                                                <select ref="prov_id" name="prov_id"
                                                    class="block border border-gray-200 w-full p-2 px-4 rounded mt-2 text-xs"
                                                    v-model="addData.status">
                                                    <option value="1"
                                                        class="block border border-gray-200 w-full p-2 px-4 rounded mt-2 text-xs">
                                                        Aktif</option>
                                                    <option value="0"
                                                        class="block border border-gray-200 w-full p-2 px-4 rounded mt-2 text-xs">
                                                        Mati</option>
                                                </select>

                                                <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                                            </div>
                                        </ValidationProvider>
                                    </div>


                                </div>

                                <div class="grid grid-cols-1 gap-4 mt-4">
                                    <div class="grid grid-cols-12 gap-4 items-center">
                                        <label for="" class="block col-span-2 md:col-span-1">Gambar</label>
                                        <div class="col-span-12 md:col-span-3">
                                            <div>
                                                <div class="flex justify-center border border-gray-200 p-4"
                                                    @click="$refs.foto_profile.click()">
                                                    <div class="w-40 h-40 ">
                                                        <img :src="addData.foto ? addData.foto : default_url + 'default.png'"
                                                            class="w-40 h-40 object-cover " />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="float-right">
                                    <button @click="$router.push('/device')" :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`">Batal</button>

                                    <button :disabled="invalid" type="submit" :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`">Simpan</button>
                                </div>


                            </form>
                        </ValidationObserver>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapAction, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({

        disabled: false,

        keyword: '',
        foto: '',
        dataGsm: [],
        addData: {
            name: '',
            code: '',
            latitude: '',
            longitude: '',
            gsm_id: '',
            foto: '',
            nohp: '',
            project: '',
            titik_nol: '',
            siaga_1: '',
            siaga_2: '',
            status: '',
            siaga_3: '',
            siaga_tiga: '',
            siaga_4: '',
        },
        delete_id: '',
        modal_edit: false,
        default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {

        getGsm() {
            this.axios.get('v1/masterdata/18', this.config)
                .then((ress) => {
                    this.dataGsm = ress.data.Masterdata_details

                })
        },


        changeProv() {
            this.district = []
            this.addData.city_id = ''
            this.addData.dis_id = ''
            this.getCity()
        },
        changeCity() {
            this.addData.dis_id = ''
            this.getDis()
        },
        async postData() {
            let data = new FormData()
            var request_form = this.addData
            await Object.keys(this.addData).forEach(function (key, val) {
                console.log(key)
                if (key != 'foto') {
                    data.append(key, request_form[key])

                }
            });

            if (this.foto) {
                data.append('foto', this.foto)
            } else {

            }

            this.axios.put('v1/device/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.$snack.success({
                        text: ress.data.message,
                    })
                    this.$router.push('/device')
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                    }
                })

        },

        getData() {
            this.axios.get('v1/device/' + this.$route.params.id, this.config)
                .then((ress) => {
                    this.addData = ress.data
                    if (this.addData.foto) {
                        this.addData.foto = this.default_url + this.addData.foto
                    }
                })
        },
        eventChange(event) {
            this.default_url = ''
            const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                this.addData.foto = fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
        },

    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
        }),

    },
    async created() {
        await this.getData()
        this.getGsm()

    },
    components: {
        ValidationProvider, ValidationObserver
    }
}
</script>